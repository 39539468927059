import React, { useEffect, useState } from 'react';
import { NvTab, NvResponsiveTabsDisplayType } from 'shared/components/nv-responsive-tabs';
import { NvResponsiveTabsRow } from 'shared/components/nv-responsive-tabs-row';
import { createGridStyles, halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import t from 'react-translate';
import { textSmallFontSize } from 'styles/global_defaults/fonts';
import { AngularServicesContext } from 'react-app';
import { AnalyticsDashBoardType } from 'redux/schemas/app/analytics';
import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { gray6, warning } from 'styles/global_defaults/colors';
import { css } from '@emotion/react';
import AnalyticsDashboard from './analytics-dashboard';
import QuizAndSurvey from './quiz-survey-analytics';

const styles = css`
  .tab-container {
    position: sticky;
    top: 0;
    z-index: 2;
    border-bottom: solid 1px ${gray6};
  }
  .quiz-survey {
    .bottom-border {
      position: relative;
      left: 0;
      top: ${halfSpacing}px;
      width: 100%;
      height: ${quarterSpacing}px;
      background-color: ${warning};
    }
  }
  .quiz-survey-dashboard {
    max-width: 1030px;
  }
  .bs4-dropdown {
    .accordion-collapse {
      overflow-y: scroll;
    }
    .expanded {
      max-height: 300px !important;
    }
  }
`;

const CourseAnalytics = () => {
  const { cohort } = useSelector((state) => state.app.qrveyConfig);
  const showCohorts = cohort;
  const skillTags = Object.values(useSelector((state) => state.models.institutions[state.app.currentInstitutionId].skillTags));
  const institutionHasSkilltags = skillTags?.length > 0;
  const history = useHistory();
  const location = useLocation();
  let currentActiveTab = 0;

  const sections = [
    {
      url: '/activities',
      activeId: 1,
      display: true,
    },
    {
      url: '/skills',
      activeId: 2,
      display: institutionHasSkilltags,
    },
    {
      url: '/cohorts',
      activeId: 3,
      display: true,
    },
  ];
  // set the default selected tab based on the url.
  sections.forEach((section) => {
    if (location?.hash?.endsWith(section.url) && section.display && !questionSetId) {
      currentActiveTab = section.activeId;
    }
  });

  const [activeTab, setActiveTab] = useState(currentActiveTab);
  const [questionSetId, setQuestionSetId] = useState(null);
  const { $state } = React.useContext(AngularServicesContext);
  const { catalogId } = $state?.params;
  const baseURL = `/#!/courses/${catalogId}/analytics`;

  const selectTab = (id) => {
    setActiveTab(id);
    setQuestionSetId(null);
    // changing history so reloading will mark the right tab
    if (id === 0) {
      history.push(baseURL);
    }
    if (id === 1) {
      history.push(`${baseURL}/activities`);
    }
    if (id === 2 && institutionHasSkilltags) {
      history.push(`${baseURL}/skills`);
    }
    if (id === 3) {
      history.push(`${baseURL}/cohorts`);
    }
  };

  const tabs: NvTab[] = [
    {
      text: t.COURSE_ANALYTICS.OVERVIEW(),
      onClick: () => selectTab(0),
    }, {
      text: t.COURSE_ANALYTICS.ACTIVITIES(),
      onClick: () => selectTab(1),
    },
  ];
  if (institutionHasSkilltags) {
    tabs.push({
      text: t.COURSE_ANALYTICS.SKILLS(),
      onClick: () => selectTab(2),
    });
  }
  if (showCohorts) {
    tabs.push({
      text: t.COURSE_ANALYTICS.COHORTS(),
      onClick: () => selectTab(3),
    });
  }

  const tabStyles = {
    ...createGridStyles(1, 2),
    fontSize: `${textSmallFontSize}px`,
  };
  const onSelectQuestion = (id: number) => {
    setQuestionSetId(id);
    setActiveTab(4);
    history.push(`${baseURL}/quiz-and-survey/${id}`);
  };

  useEffect(() => {
    if ($state.params?.questionId) {
      setQuestionSetId($state.params?.questionId);
      setActiveTab(4);
    }
  }, [$state.params?.questionId]);

  return (
    <div css={styles}>
      <div className='d-flex align-items-center pr-2 bg-white tab-container'>
        <NvResponsiveTabsRow
          style={tabStyles}
          defaultTabs={tabs}
          tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
          tabTextClass='card-title'
          selectedTabIndex={activeTab}
          revertActiveTab={questionSetId ? -1 : activeTab}
        />
        <div className='quiz-survey ml-4'>
          <QuizAndSurvey
            onSelect={onSelectQuestion}
            selectedId={questionSetId}
          />
          {questionSetId && (
            <div className='bottom-border' />
          )}
        </div>
      </div>

      {/* This to conditionals are neccesary to rerender the qrvey instance on click of the tabs */}
      {activeTab === 0
      && <AnalyticsDashboard dashboardType={AnalyticsDashBoardType.COURSE} configKeyName='config_overview' pageIdKey='overview' /> }
      {activeTab === 1
      && <AnalyticsDashboard dashboardType={AnalyticsDashBoardType.COURSE} configKeyName='config_activities' pageIdKey='activities' />}
      {activeTab === 2
      && <AnalyticsDashboard dashboardType={AnalyticsDashBoardType.COURSE} configKeyName='config_skills' pageIdKey='skills' />}
      {activeTab === 3
      && <AnalyticsDashboard dashboardType={AnalyticsDashBoardType.COHORTS} configKeyName='config_cohorts' pageIdKey='cohorts' />}
      {questionSetId
       && (
       <div className='d-flex justify-content-center quiz-survey-dashboard mx-auto'>
         <AnalyticsDashboard
           dashboardType={AnalyticsDashBoardType.QUESTION_SET}
           configKeyName={`config_question_set_${questionSetId}`}
           pageIdKey='question_set'
           questionSetId={questionSetId}
         />
       </div>
       )}
    </div>

  );
};
export default CourseAnalytics;

export const CourseAnalyticsRoute = () => <BrowserRouter forceRefresh><CourseAnalytics /></BrowserRouter>;
