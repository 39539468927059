import React, { useCallback, useEffect, useMemo } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';

import { fetchQuestionSet } from 'redux/actions/analytics';
import { useAppDispatch } from 'redux/store';
import NvDropdown, {
  NvDropdownOption, NvDropdownButtonStyle, NvDropdownAlign,
  NvDropdownAccordionHeader,
} from 'shared/components/inputs/nv-dropdown';
import { find, keys } from 'underscore';
import NvIcon from 'shared/components/nv-icon';

const keyMapping = {
  quizzes: 'QUIZ',
  timedExams: 'TIMED_EXAM',
  surveys: 'SURVEY',
};

const QuizAndSurvey = (props: { onSelect: (id) => void, selectedId: number }) => {
  const institutionId = useSelector(state => state.app.currentInstitutionId);
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const { questionSets, questionSetsLoaded } = useSelector(state => state.app.analytics);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchQuestionSet({ institutionId, catalogId }));
  }, [catalogId, dispatch, institutionId]);

  const onSelectQuestionSet = useCallback((questionSetId) => {
    props.onSelect(questionSetId);
  }, [props]);

  const getCategories = useMemo(() => {
    const categories: NvDropdownOption[] = keys(questionSets)?.map(key => ({
      type: 'accordion-header',
      title: t.COURSE_ANALYTICS[keyMapping[key]](),
      key,
      items: questionSets[key].map(item => ({
        type: 'text',
        text: item.title,
        id: item.id,
        key,
        isChecked: item.id === props.selectedId,
        callback: () => onSelectQuestionSet(item.id),
      })),
    }));
    return categories;
  }, [questionSets, props.selectedId, onSelectQuestionSet]);

  const findInitialIndex = () => {
    let initialIndex;
    find(getCategories, (value: NvDropdownAccordionHeader) => {
      initialIndex = value.items.findIndex(item => item.id === props.selectedId);

      if (initialIndex >= 0) {
        return true;
      }
    });
    return initialIndex;
  };

  return (
    <NvDropdown
      buttonStyle={NvDropdownButtonStyle.CUSTOM}
      items={getCategories}
      align={NvDropdownAlign.RIGHT}
      noMenuPadding
      initialIndex={findInitialIndex()}
      showSelectedIndicator={!!props.selectedId}
      customTarget={() => (
        questionSetsLoaded && (
          <div className='d-flex align-items-center card-title px-4'>
            {t.COURSE_ANALYTICS.SURVEY_AND_QUIZ()}
            <NvIcon icon='dropdown-arrow' size='xss-smallest' className='ml-3 mt-2' />
          </div>
        )
      )}
    />
  );
};

export default QuizAndSurvey;
